import cn from 'classnames';
import s from './SlideCard.module.scss';
import { CarouselSection } from '../../types';
import { renderHTMLString } from '../../../../../../../../helpers';
import ImageTag from '../../../../../../../shared/ImageTag/ImageTag';
import AnimatedCallToAction from '../../../../../AnimatedCallToAction';
import { PropsWithTestId } from '../../../../../../../../types';
import { HTMLAttributes } from 'react';

export interface SlideCardProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  slide: CarouselSection;
  ctaLabel?: string;
}

export const defaultProps = {
  testId: 'slide-card',
};

export default function SlideCard(props: PropsWithTestId<SlideCardProps>) {
  const { slide, ctaLabel, className } = props;
  const { title, body: text, image, alt } = slide;

  return (
    <div className={cn(s.wrapper, className, 'animated-cta-trigger')}>
      <div className={s.imageWrapper}>
        {image?.path ? (
          <ImageTag src={image.path} alt={alt} className={s.image} />
        ) : (
          <div className={s.fakeImage} />
        )}
      </div>
      <div className={s.content}>
        <div className={s.body}>
          {title && <div className={s.title}>{title}</div>}
          {text && <div className={s.text}>{renderHTMLString(text)}</div>}
        </div>
        <div className={s.footer}>
          {ctaLabel && <AnimatedCallToAction>{ctaLabel}</AnimatedCallToAction>}
        </div>
      </div>
    </div>
  );
}

SlideCard.defaultProps = defaultProps;
