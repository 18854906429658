import cn from 'classnames';
import s from './CarouselModule.module.scss';
import { PropsWithTestId } from '../../../../../../types';
import { CarouselModuleType } from './types';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import LargeImagesDisplayStyle from './LargeImagesDisplayStyle';
import ImageAndTextDisplayStyle from './ImageAndTextDisplayStyle';

export interface CarouselModuleProps {
  module: CarouselModuleType;
}

export const defaultProps = {
  testId: 'hub-carousel-module',
};

export default function CarouselModule(
  props: PropsWithTestId<CarouselModuleProps>,
) {
  const { module, testId } = props;
  const { '@type': type, elementId, visualStyle, displayStyle } = module;

  const LARGE_IMAGES = displayStyle === 'LARGE_IMAGES';
  const IMAGE_AND_TEXT = displayStyle === 'IMAGE_AND_TEXT';

  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      testId={testId}
      className={cn(s.wrapper, {
        [s.largeImagesWrapper]: LARGE_IMAGES,
        [s.imageAndTextWrapper]: IMAGE_AND_TEXT,
        [s.removeBottomPadding]: LARGE_IMAGES,
      })}
    >
      {LARGE_IMAGES && <LargeImagesDisplayStyle module={module} />}
      {IMAGE_AND_TEXT && <ImageAndTextDisplayStyle module={module} />}
    </HubModuleWrapper>
  );
}

CarouselModule.defaultProps = defaultProps;
