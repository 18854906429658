import { useState } from 'react';
import { CarouselModuleType } from '../types';
import { ModuleHeader } from '../../../components';
import Slider, { SliderSettings } from '../../../../Slider';
import s from './LargeImagesDisplayStyle.module.scss';
import BlockAnchor from '../../../../BlockAnchor';
import SlideContent from './SlideContent';
import SlideImage from './SlideImage';

const contentSliderSettings: SliderSettings = {
  fade: true,
  arrows: false,
};

const imageSliderSettings: SliderSettings = {
  centerMode: true,
  variableWidth: true,
  useCSS: false,
};

export interface LargeImagesDisplayStyleProps {
  module: CarouselModuleType;
}

export default function LargeImagesDisplayStyle(
  props: LargeImagesDisplayStyleProps,
) {
  const { module } = props;
  const { title, text, subsections: slides } = module;

  const [contentSlider, setContentSlider] = useState();
  const [imageSlider, setImageSlider] = useState();

  return (
    <div className={s.wrapper}>
      <div className="container">
        {(title || text) && (
          <div className="row">
            <div className="col-xs-12 col-md-7 col-lg-5">
              <div className={s.headerWrapper}>
                <ModuleHeader title={title} text={text} />
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-xs-12">
            <div className={s.contentSliderWrapper}>
              <Slider
                asNavFor={imageSlider}
                setRef={(slider) => setContentSlider(slider)}
                slides={slides}
                renderSlide={(slide) => (
                  <BlockAnchor link={slide.link}>
                    {(link) => (
                      <SlideContent slide={slide} ctaLabel={link?.label} />
                    )}
                  </BlockAnchor>
                )}
                settings={contentSliderSettings}
              />
            </div>
            <div className={s.imageSliderWrapper}>
              <Slider
                asNavFor={contentSlider}
                setRef={(slider) => setImageSlider(slider)}
                slides={slides}
                renderSlide={(slide) => (
                  <BlockAnchor>
                    {() => <SlideImage slide={slide} />}
                  </BlockAnchor>
                )}
                settings={imageSliderSettings}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
