import cn from 'classnames';
import s from './SlideContent.module.scss';
import { CarouselSection } from '../../types';
import { renderHTMLString } from '../../../../../../../../helpers';
import AnimatedCallToAction from '../../../../../AnimatedCallToAction';

export interface SlideContentProps {
  slide: CarouselSection;
  ctaLabel?: string;
}

export default function SlideContent({ slide, ctaLabel }: SlideContentProps) {
  const { title, body: text, labelTitle } = slide;

  return (
    <div className={cn(s.wrapper, 'animated-cta-trigger')}>
      <div className="row">
        <div className="col-xs-12 col-sm-8 col-lg-5">
          {labelTitle && <div className={s.labelTitle}>{labelTitle}</div>}
          {title && <div className={s.title}>{title}</div>}
          {text && <div className={s.text}>{renderHTMLString(text)}</div>}
        </div>
      </div>
      {ctaLabel && (
        <div className={s.ctaWrapper}>
          <AnimatedCallToAction>{ctaLabel}</AnimatedCallToAction>
        </div>
      )}
    </div>
  );
}
