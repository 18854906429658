import cn from 'classnames';
import s from './SlideImage.module.scss';
import { CarouselSection } from '../../types';
import { useGetImgixImageUrl } from '../../../../../../../../hooks';

export interface SlideImageProps {
  slide: CarouselSection;
}

export default function SlideImage({ slide }: SlideImageProps) {
  const imageUrl = useGetImgixImageUrl(slide.image?.path);

  return (
    <div className={cn(s.wrapper, 'embed-responsive embed-responsive-1by1')}>
      <div
        data-background-image={imageUrl ?? undefined}
        className={cn(s.image, 'lazy embed-responsive embed-responsive-2by1')}
        data-testid="slide-image"
      />
    </div>
  );
}
