import s from './ImageAndTextDisplayStyle.module.scss';
import { CarouselModuleType } from '../types';
import { ModuleHeader } from '../../../components';
import Slider, { SliderSettings } from '../../../../Slider';
import SlideCard from './SlideCard';
import BlockAnchor from '../../../../BlockAnchor';

const sliderSettings: SliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
      breakpoint: 1024,
    },
    {
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      breakpoint: 767,
    },
  ],
};

export interface ImageAndTextDisplayStyleProps {
  module: CarouselModuleType;
}

export default function ImageAndTextDisplayStyle(
  props: ImageAndTextDisplayStyleProps,
) {
  const {
    module: { title, text, subsections: slides },
  } = props;

  return (
    <div className={s.wrapper}>
      <div className="container">
        {(title || text) && (
          <div className="row">
            <div className="col-xs-12 col-md-7 col-lg-5">
              <div className={s.headerWrapper}>
                <ModuleHeader title={title} text={text} />
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-xs-12">
            <div className={s.sliderWrapper}>
              <Slider
                slides={slides}
                renderSlide={(slide) => (
                  <BlockAnchor link={slide.link}>
                    {(link) => (
                      <SlideCard
                        slide={slide}
                        ctaLabel={link?.label}
                        className={s.slide}
                      />
                    )}
                  </BlockAnchor>
                )}
                settings={sliderSettings}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
